import Glide, { Breakpoints, Autoplay } from '@glidejs/glide/dist/glide.modular.esm'

document.addEventListener('turbo:load', () => {
  if(document.querySelector('.glide__slide')) {
    const glide = new Glide('.glide', {
      type: 'slider',
      bound: true,
      focusAt: 0, // Slide to focus on
      startAt: 0, // Starting slide index
      perView: 1, // Number of slides to show
      autoplay: 5000,
      hoverpause: false,
      breakpoints: {
        768: {
          perView: 1
        },
        1200: {
          perView: 1
        },
      },
      rewind: true, // Rewind to the first slide upon reaching the end
    })

    glide.on('run', () => {
      Array.from(glideArrows).forEach((arrow, index) => {
        if (index === glide.index) {
          arrow.classList.add('selected')
        } else {
          arrow.classList.remove('selected')
        }
      })
    })

    const glideArrows = document.querySelectorAll('.glide__arrow')
    
    Array.from(glideArrows).forEach((arrow, index) => {
      arrow.addEventListener('click', (e) => {
        Array.from(glideArrows).forEach((a) => {
          a.classList.remove('selected')
          
        })
        e.currentTarget.classList.add('selected')
        glide.go(`=${index}`) // Go to the next slide
      })
    })
    glide.mount({ Breakpoints, Autoplay }) // Mount the carousel
  }
})